import React, { useState, useEffect } from 'react';
import { FaLightbulb, FaMoon } from 'react-icons/fa';
import { Box, Typography, ThemeProvider, createTheme } from '@mui/material';
import { styled } from '@mui/system';

const CountdownBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  backgroundColor: 'rgba(173, 216, 230, 0.2)', // Light blue background
  borderRadius: '15px', // Rounded corners
  padding: theme.spacing(2),
  maxWidth: '80%', // Limit width to keep it centered
  margin: '0 auto', // Center the box
}));

const TimeUnit = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: theme.spacing(0, 1), // Reduced margin to pack components closer
}));

const TimeValue = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 'bold',
}));

const TimeLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  textTransform: 'uppercase',
}));

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [timeLeft, setTimeLeft] = useState({});

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = +new Date('2024-10-01') - +new Date();
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }

      return timeLeft;
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  const formatTime = (time) => time.toString().padStart(2, '0');

  return (
    <ThemeProvider theme={theme}>
      <div className={`App ${darkMode ? 'dark-mode' : 'light-mode'}`}>
        <div className="container">
          <h1>Welcome to The Intellect Hub</h1>
          <p>The learning platform is under construction and will be available on October 1st, 2024. Join the waiting list below to gain free beta access!</p>
          
          {darkMode ? (
            <FaLightbulb onClick={toggleDarkMode} className="toggle-button" />
          ) : (
            <FaMoon onClick={toggleDarkMode} className="toggle-button" />
          )}

          <div className="video-container">
            <iframe 
              className='video_iframe'
              width="560" 
              height="315" 
              src="https://www.youtube.com/embed/C9_6ycnpjW4" 
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
            ></iframe>
          </div>

          <CountdownBox>
            {['days', 'hours', 'minutes', 'seconds'].map((unit) => (
              <TimeUnit key={unit}>
                <TimeValue>{formatTime(timeLeft[unit] || 0)}</TimeValue>
                <TimeLabel>{unit}</TimeLabel>
              </TimeUnit>
            ))}
          </CountdownBox>

          <br/>
          <br/>
          <br/>

          <a href="https://www.producthunt.com/posts/the-intellect-hub?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-the-intellect-hub" target="_blank" rel="noopener noreferrer">
            <img 
              src={`https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=490182&theme=${darkMode ? 'dark' : 'light'}`}
              alt="The Intellect Hub - Unlock your potential with tailored learning journeys | Product Hunt" 
              style={{ width: '250px', height: '54px' }} 
              width="250" 
              height="54" 
            />
          </a>

          <br/>
          <br/>
          <br/>

          <div className="form-container" id="waiting-list-form">
            <iframe 
              className='form_iframe'
              title="Joining Waiting List"
              src="https://docs.google.com/forms/d/e/1FAIpQLSe2KCDWCKERXKzCkhYBVa04a7-ImmjoZj64dzJGQV5sG6Ps5w/viewform?usp=sf_link" 
              width="100%" 
              height="500px" 
              frameBorder="0" 
              marginHeight="0" 
              marginWidth="0">Loading…
            </iframe>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
// https://docs.google.com/forms/d/e/1FAIpQLSe2KCDWCKERXKzCkhYBVa04a7-ImmjoZj64dzJGQV5sG6Ps5w/viewform?usp=sf_link
